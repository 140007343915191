@import '../../css/constants';

.card {
    min-height: 500px;
    padding: 80px 36px 16px;
    background-color: $white;
    border-radius: 8px;
    box-shadow: $longShadow;
    h4 {
        font-size: 1.5rem;
        font-weight: 700;
        color: $darkGray;
        margin: 0 0 64px;
    }
    p {
        font-size: 1.5rem;
        color: $gray;
        margin: 0;
    }
}