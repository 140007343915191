@import '../../css/constants';

.card {
    padding: 78px 40px;
    background-color: $white;
    border-radius: 8px;
    box-shadow: $defaultShadow;
    h4 {
        font-size: 1.5rem;
        font-weight: 700;
        color: $darkGray;
        margin: 0 0 40px;
    }
    p {
        font-size: 1.5rem;
        color: $gray;
        margin: 0;
    }
    ul {
        padding-left: 24px;
        li {
            font-size: 1.5rem;
            color: $gray;
            list-style-type: disc;
        }
    }
}

.image {
    display: block;
    width: 100%;
    height: 600px;
    object-fit: contain;
    object-position: center center;
}