@import '../../css/constants';

.card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 400px;
    width: 80%;
    margin: auto;
    margin-bottom: 28px;
    padding-left: 40px;
    padding-right: 40px;
    background-color: $white;
    border-radius: 8px;
    box-shadow: $defaultShadow;
    img {
        display: block;
        margin-bottom: 32px;
        width: 80px;
        height: 90px;
        object-fit: contain;
    }
    h4 {
        font-family: $catamaran;
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 32px;
        color: $darkGray;
        margin: 0 0 16px;
    }
    p {
        font-size: 1rem;
        font-weight: 400;
        color: $gray;
    }
}

.gallery {
    display: block;
    width: 100%;
    margin-bottom: 16px;
}