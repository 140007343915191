@import '../../css/constants';


.background {
    background-image: url('../../assets/images/home/blue-bg.svg');
    background-position: right center;
    background-repeat: no-repeat; 
    background-size: 30% 100%;
}

.card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 0 30px rgba(0,0,0,0.1);
    height: 324px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 30px;
    padding: 0 24px;
    h4{
        font-family: $catamaran;
        font-size: 1.5rem;
        font-weight: 700;
        color: $darkGray;
        margin: 0;
    }
    p {
        font-family: $catamaran;
        font-size: 1rem;
        color: $gray;
        margin: 0;
    }
    img {
        width: 56px;
        height: 56px;
        object-fit: contain;
        margin-bottom: 24px;
    }
}