@import '../../css/constants';

.card {
    position: relative;
    padding: 64px 32px 36px;
    background-color: $white;
    border-radius: 8px;
    box-shadow: $defaultShadow;
    margin-bottom: 40px;
    img {
        display: block;
        position: absolute;
        top: -25px;
        margin-bottom: 28px;
    }
    h4 {
        font-family: $catamaran;
        font-size: 1.5rem;
        font-weight: 700;
        color: $darkGray;
        margin: 0 0 24px;
    }
    p{
        font-size: 1rem;
        color: $gray;
        margin: 0;
    }
}