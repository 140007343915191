@import '../../css/constants';

.contact {
    min-height: 100vh;
    background-color: #242424;
}
.close {
    cursor: pointer;
    position: fixed;
    top: 36px;
    right: 36px;
}

.subtitle {
    font-family: $catamaran;
    font-size: 1rem;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 4px;
    color: $white;
    margin: 0 0 12px;
}

.title {
    font-family: $catamaran;
    font-size: 3rem;
    font-weight: 700;
    line-height: 60px;
    color: $white;
    margin: 0 0 44px;
}

.separator {
    display: block;
    width: 104px;
    height: 8px;
    background-color: $blue;
    margin-bottom: 28px;
}

.text {
    font-family: $catamaran;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 24px;
    color: $white;
    margin: 0 0 50px;
}

.address{
    img {
        display: block;
        margin-right: 28px;
    }
    p {
        margin: 0;
    }
    small, a {
        font-size: 0.875rem;
        color: $white;
    }
}

.form {
    label {
        display: block;
        font-family: $catamaran;
        font-size: 1rem;
        font-weight: 700;
        color: $white;
        margin: 0 0 18px;
    }
    input, textarea {
        display: block;
        width: 100%;
        padding: 17px 16px;
        margin: 0 0 16px;
        border: 2px solid $white;
        border-radius: 8px;
        font-family: $mulish;
        font-size: 1rem;
        color: $darkGray;
        transition: all 200ms ease;
        &:focus {
            outline: none;
            border-color: $blue;
        }
    }
    textarea {
        min-height: 112px;
        resize: none;
        margin: 0 0 24px;
    }
}

.error {
    border-color: #FF6262 !important;
}
.errorText {
    font-size: 0.875rem;
    color: $white;
    padding-left: 24px;
    background: url('../../assets/images/contactanos/icon-error.svg') no-repeat left center / 16px;
}

.card {
    padding: 40px 24px;
    background-color: $white;
    border-radius: 8px;
    box-shadow: $defaultShadow;
    img {
        cursor: pointer;
    }
    p {
        color: $gray;
        strong {
            color: $darkGray;
        }
        &:last-child {
            margin: 0;
        }
    }
}

//Google Form
iframe{
    position: relative;
    width: 100%;
    height: 1400px;
    overflow: hidden;
    scrollbar-width: none;
}

.ulIcon{
    margin-bottom: 48px;
}
.icons {
    width: 25px;
    height: 25px;
    border-radius: 25px;
    background-color: #FFFFFF;
    margin-bottom: 5px;
    margin-left: 5px;
}