@import '../../css/constants';

.card {
    margin-bottom: 48px;
    padding: 72px 36px;
    background: $white;
    border-radius: 8px;
    box-shadow: 0 0 30px rgba(0,0,0,0.1);
    h4 {
        font-family: $catamaran;
        font-size: 1.5rem;
        line-height: 32px;
        font-weight: 700;
        color: $darkGray;
        margin: 0;
    }
    p {
        margin: 64px 0 0;
        color: $gray;
    }
}