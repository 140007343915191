@import '../../css/constants';

.card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 0 30px rgba(0,0,0,0.1);
    height: 190px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 22px;
}