@import '../../css/constants';

.logo {
    width: 190px;
    height: 100px;
    object-fit: contain;
    object-position: 0;
}
.small {
    width: 130px;
    height: 100px;
    object-fit: contain;
}

.menu {
    margin: 24px 0 0;
    cursor: pointer;
}

.mobileNav {
    position: fixed;
    z-index: 100;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px;
    background-color: #242424;
    overflow: auto;
    ul {
        padding: 0;
        display: flex;
        justify-content: space-between;
        li{
            a{
                color: $white;
                text-decoration: none;
                font-size: 0.625rem;
                &::before {
                    display: block;
                    content: attr(title);
                    font-weight: 700;
                    height: 0;
                    overflow: hidden;
                    visibility: hidden;
                }
                &::after {
                    display: block;
                    content: "";
                    margin-top: 12px;
                    height: 3px;
                    border-radius: 2px;
                    background-color: $blue;
                    opacity: 0;
                    transition: all 300ms ease;
                }
            }
        }
    }
}

.navigation {
    ul{
        display: flex;
        justify-content: space-between;
        li a{
            display: inline-block;
            text-align: center;
            font-size: 1rem;
            color: $white;
            text-decoration: none;
            transition: all 200ms ease;
            &::before {
                display: block;
                content: attr(title);
                font-weight: 700;
                height: 0;
                overflow: hidden;
                visibility: hidden;
            }
            &::after {
                display: block;
                content: "";
                margin-top: 12px;
                height: 4px;
                border-radius: 2px;
                background-color: $blue;
                opacity: 0;
                transition: all 300ms ease;
            }
            &:hover {
                text-decoration: none;
                font-weight: 700;
                &::after {
                    opacity: 1;
                    margin-top:8px;
                }
            }
        }
    }
}

.active {
    font-weight: 700;
    &::after {
        margin-top: 8px !important;
        opacity: 1 !important;
    }
}

.icons {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background-color: #FFFFFF;
    margin-bottom: 5px;
    margin-left: 5px;
}