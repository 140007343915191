// RESET
* {
    box-sizing:border-box;
}
body {
    font-family: 'Mulish', sans-serif;
    margin: 0;
    padding: 0;
    html {
        scroll-behavior: smooth;
    }
    ul, ol, li{
        list-style: none;
        margin: 0;
    }
}

// BUTTONS
.button-md {
    &.MuiButton-root {
        height: 68px;
        border-radius: 34px !important;
        .MuiButton-label {
            font-family: 'Catamaran', sans-serif;
            font-size: 1.5rem;
            font-weight: 400;
            text-transform: none;
        }
    }
}

.button-sm {
    &.MuiButton-root {
        height: 52px;
        border-radius: 26px !important;
        .MuiButton-label {
            font-family: 'Catamaran', sans-serif;
            font-size: 0.875rem;
            font-weight: 400;
            text-transform: none;
        }
    }
}

.button-contact {
    &.MuiButton-root {
        height: 52px;
        border-radius: 8px !important;
        .MuiButton-label {
            font-size: 0.875rem;
            font-weight: 700;
            text-transform: none;
        }
    }
}

// SHADOWS
.long-shadow {
    border-radius: 8px;
    box-shadow: 56px 40px 112px rgba(0,0,0,0.4);
}