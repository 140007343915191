@import '../../css/constants';

.title {
    font-size: 1.5rem;
    font-weight: 700;
    color: $darkGray;
    margin: 0;
}

.image {
    display: block;
    height: 90px;
    width: 140px;
    object-fit: contain;
}