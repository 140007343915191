@import '../../css/constants';

.card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 400px;
    margin-bottom: 28px;
    padding-left: 36px;
    padding-right: 36px;
    background-color: $white;
    border-radius: 8px;
    box-shadow: $defaultShadow;
    h4 {
        font-family: $catamaran;
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 32px;
        color: $darkGray;
        margin: 0 0 14px;
    }
    p {
        font-size: 1rem;
        font-weight: 400;
        color: $gray;
        margin: 0;
    }
}

.gallery {
    display: block;
    width: 100%;
    margin-bottom: 16px;
}
