@import '../../css/constants';

.card {
    padding: 68px 56px;
    background-color: $white;
    border-radius: 8px;
    box-shadow: $defaultShadow;
}

.list {
    padding-left: 24px;
    li{
        font-size: 1.5rem;
        color: $gray;
        list-style-type: disc;
    }
}

.image {
    display: block;
    width: 100%;
}