@import '../../css/constants';

.card {
    padding: 56px 36px;
    background-color: $white;
    border-radius: 8px;
    box-shadow: $defaultShadow;
    h4 {
        font-size: 1.5rem;
        font-weight: 700;
        color: $darkGray;
        margin: 0 0 36px;
    }   
    ul {
        padding-left: 24px;
        li {
            font-size: 1.5rem;
            color: $gray;
            list-style-type: disc;
        }
    }
}