@import '../../css/constants';


.subtitle {
    font-family: $catamaran;
    font-size: 1.125rem;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 8px;
    color: $gray;
    margin: 0 0 24px;
}

.title {
    font-family: $catamaran;
    font-size: 3rem;
    font-weight: 700;
    line-height: 50px;
    color: $darkGray;
    margin: 0 0 24px;
}

.paragraph {
    font-family: $monse;
    font-size: 1.5rem;
    line-height: 30px;
    color: $gray;
    margin: 0 0 24px;
    ul{
        margin-top: 36px;
        padding-left: 24px;
        li{
            list-style-type: disc;
        }
    }
}

.extra {
    font-family: $catamaran;
    font-size: 1.5rem;
    line-height: 24px;
    font-weight: 700;
    color: $gray;
    margin: 0;
}

.image {
    display: block;
    width: 100%;
    min-height: 580px;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 56px 40px 112px rgba(0,0,0,0.4);
}