@import '../../css/constants';

.footer {
    padding-top: 80px;
    background-color: #242424;
}
.logo {
    height: 90px;
    margin-bottom: 20px;
}

.logoBlock {
    h3 {
        font-size: 1rem;
        font-weight: 700;
        color: $white;
        margin-top: 16px;
        margin-bottom: 30px;
    }
    p{
        margin: 0 0 16px 8px;
    }
    small, a {
        font-size: 0.875rem;
        color: $white;
    }
    a {
        text-decoration: underline;
    }
}


.whiteBlock {
    h3 {
        font-size: 1rem;
        font-weight: 700;
        color: $white;
        margin: 16px 0 65px 0;
    }
    li {
        margin: 0 0  26px 0;
        a {
            text-decoration: none;
            font-size: 0.875rem;
            color: $white;
            cursor: pointer;
        }
    }
}

.whiteBG {
    h3 {
        font-size: 1rem;
        font-weight: 700;
        color: $black;
        margin: 16px 0 65px 0;
    }
    p {
        font-size: 0.875rem;
        color: $gray;
    }
    a {
        margin-top: 30px;
    }
}
.blackBG {
    h3 {
        font-size: 1rem;
        font-weight: 700;
        color: $white;
        margin: 16px 0 65px 0;
    }
    p {
        font-size: 0.875rem;
        color: $white;
    }
    a {
        margin-top: 30px;
    }
}

.ulIcon{
    margin-bottom: 15px;
}

.icons {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    background-color: #FFFFFF;
    margin-bottom: 5px;
    margin-left: 5px;
}