@import '../../css/constants';

.text {
    font-family: $catamaran;
    font-size: 1.5rem;
    color: $gray;
}

.card {
    padding: 56px 0;
    margin-bottom: 18px;
    background-color: $white;
    border-radius: 8px;
    box-shadow: $defaultShadow;
    img {
        display: block;
        margin: auto;
        width:70%;
        height: 68px;
        object-fit: scale-down;
    }
}