@import '../../css/constants';

.year {
    font-size: 1.938rem;
    font-weight: 700;
    color: $darkGray;
    margin: 0;
}

.bullet {
    margin-top: 7px;
}

.line {
    display: block;
    width: 1px;
    height: 224px;
    flex: 2;
    background: #cccccc;
    margin-top: -10px;
}

.title {
    font-size: 1.5rem;
    font-weight: 700;
    color: $darkGray;
    margin: 2px 0 27px;
}

.text {
    font-size: 1rem;
    color: $gray;
    margin: 0;
}