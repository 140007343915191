// COLORS

$blue: #3399FF;
$white: #FFFFFF;
$black: #000000;
$darkGray: #2E2E2E;
$gray: #808080;

//FONT FAMILY
$mulish: 'Mulish', sans-serif;
$catamaran: 'Catamaran', sans-serif;
$monse: 'Montserrat', sans-serif;

//SHADOWS

$defaultShadow: 0 0 30px rgba(0,0,0,0.1);
$longShadow: 56px 40px 112px rgba(0,0,0,0.4);