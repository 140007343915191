@import '../../css/constants';

.list {
    padding-left: 24px;
    li {
        font-family: $monse;
        font-size: 1.5rem;
        color: $gray;
        list-style-type: disc;
    }
}

.card {
    padding: 40px 56px;
    background-color: $white;
    border-radius: 8px;
    box-shadow: $defaultShadow;
    img {
        display: block;
    }
    ul{
        padding-left: 24px;
        margin-top: 36px;
        li {
            font-family: $catamaran;
            font-size: 1rem;
            color: $gray;
            list-style-type: disc;
        }
        + img {
            margin-top: 36px;
        }
    }
}

.imagelist {
    img {
        width: 40%;
        height: 80px;
        object-fit: scale-down;
        margin: 8px 5%;
    }
}