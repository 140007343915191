@import '../../css/constants';

.name {
    font-size: 1.5rem;
    font-weight: 700;
    color: $darkGray;
    margin: 0 0 56px;
}

.image {
    width: 80px;
    height: 90px;
    object-fit: contain;
    margin-bottom: 36px;
}