@import '../../css/constants';

.card {
    position: relative;
    padding: 88px 24px 48px;
    background: $white;
    border-radius: 8px;
    box-shadow: 56px 40px 112px rgba(0,0,0,0.4);
}

.iconLeft {
    position: absolute;
    top: -50px;
    left: 24px;
    width: 100px;
    height: 100px;
    object-fit: contain;
}

.iconRight {
    position: absolute;
    top: -50px;
    right: 24px;
    width: 100px;
    height: 100px;
    object-fit: contain;
}

.title {
    font-size: 1.5rem;
    color: $darkGray;
    margin: 0 0 36px;
}

.description {
    font-size: 1.5rem;
    color: $gray;
    margin: 0 0 56px;
}