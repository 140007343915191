@import '../../css/constants';

.card {
    background-color: $white;
    border-radius: 8px;
    height: 190px;
    box-shadow: $defaultShadow;
    margin-bottom: 24px;
    img{
        height: 190px;
        width: 100%;
        object-fit: scale-down;
    }
}

.card2 {
    width: 100%;
    padding: 90px 56px;
    background-color: $white;
    border-radius: 8px;
    box-shadow: $defaultShadow;
}

.list{
    padding-left: 24px;
    li{
        font-size: 1.5rem;
        color: $gray;
        list-style-type: disc;
    }
}