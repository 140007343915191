@import '../../css/constants';

.card {
    margin: 0 0 40px;
    min-height: 200px;
    padding: 16px 24px;
    background-color: $white;
    border-radius: 8px;
    box-shadow: $defaultShadow;
    h4 {
        font-family: $catamaran;
        font-size: 3rem;
        font-weight: 700;
        line-height: 56px;
        color: $darkGray;
        margin: 0 0 12px;
        span {
            color: $blue;
        }
    }
    p {
        font-family: $catamaran;
        font-size: 1rem;
        font-weight: 700;
        color: $gray;
        margin: 0;
    }
}

.image {
    display: block;
    width: 100%;
}