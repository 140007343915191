@import '../../css/constants';

.card {
    min-height: 620px;
    padding-bottom: 16px;
    box-shadow: $longShadow;
    border-radius: 8px;
    background-color: $white;
    img {
        display: block;
        width: 100%;
    }
    h4 {
        font-size: 1.5rem;
        font-weight: 700;
        color: $darkGray;
        margin: 52px 56px 48px;
    }
    p {
        font-size: 1.5rem;
        color: $gray;
        margin-left: 56px;
        margin-right: 56px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}