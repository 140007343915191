@import '../../css/constants';


.subtitle {
    font-family: $catamaran;
    font-size: 1.125rem;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 8px;
    color: $gray;
    margin: 0 0 24px;
}

.subtitleSmall {
    font-family: $catamaran;
    font-size: 1.125rem;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 4px;
    color: $gray;
    margin: 0 0 24px;
}

.title {
    font-family: $catamaran;
    font-size: 3rem;
    font-weight: 700;
    line-height: 50px;
    color: $darkGray;
    margin: 0;
}

.titleSmall {
    font-family: $catamaran;
    font-size: 2rem;
    font-weight: 700;
    line-height: 2rem;
    color: $darkGray;
    margin: 0;
}

.separator {
    width: 104px;
    height: 8px;
    background-color: $blue;
    margin-top: 16px;
}

.boldDescription {
    font-family: $catamaran;
    font-size: 1.5rem;
    font-weight: 800;
    color: $gray;
    margin: 32px 0 48px;
}

.normalDescription {
    font-size: 1.5rem;
    font-weight: 400;
    color: $gray;
    margin: 32px 0 48px;
}

