@import '../../css/constants';

.text {
    p,li{
        color: $gray;
    }
    ol {
        padding: 0;
    }
}

.card {
    background-color: $white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 220px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding: 16px 32px;
    border-radius: 8px;
    box-shadow: $defaultShadow;
    margin-bottom: 36px;
    h4{
        font-family: $catamaran;
        font-size: 3rem;
        line-height: 56px;
        font-weight: 700;
        color: $darkGray;
        margin: 0 0 16px;
    }
    p {
        font-family: $catamaran;
        font-weight: 700;
        color: $gray;
        margin: 0;
    }
}