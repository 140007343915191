@import '../../css/constants';

.card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 0 30px rgba(0,0,0,0.1);
    height: 220px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 20px;
    padding: 0 32px;
    h4 {
        font-family: $catamaran;
        font-size: 3rem;
        font-weight: 700;
        color: $darkGray;
        margin: 0;
    }
    p {
        font-family: $catamaran;
        font-size: 1rem;
        font-weight: 700;
        color: $gray;
        line-height: 20px;
        margin: 0;
    }
}

.logo {
    height: 44px;
    width: 44px;
    object-fit: contain;
}